import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <div className="fondoFotter">
            <div className="container">
                <div className="row m-3">
                    <div className="col-6 col-md-4 text-center">
                        <img className="iconFooterMarcas m-3" src={process.env.PUBLIC_URL + "/images/logos/Campbell.png"} width="50%" />
                    </div>
                    <div className="col-6 col-md-4 text-center">
                        <img className="iconFooterMarcas m-3" src={process.env.PUBLIC_URL + "/images/logos/LOGO-HELVEX-SIN-FONDO.png"} width="50%"/>
                    </div>
                    <div className="col-6 col-md-4 text-center">
                        <img className="iconFooterMarcas m-3" src={process.env.PUBLIC_URL + "/images/logos/logo-comex.png"} width="50%" />
                    </div>
                    <div className="col-6 col-md-4 text-center">
                        <img className="iconFooterMarcas m-3" src={process.env.PUBLIC_URL + "/images/logos/NASSER-LOGO.png"} width="50%" />
                    </div>
                    <div className="col-6 col-md-4 text-center mt-4">
                        <img className="iconFooterMarcas m-3" src={process.env.PUBLIC_URL + "/images/logos/LOGO-WALMART.png"} width="50%" />
                    </div>
                    <div className="col-6 col-md-4 text-center">
                        <img className="iconFooterMarcas m-3" src={process.env.PUBLIC_URL + "/images/logos/interceramic.png"} width="50%" />
                    </div>
                    <div className="col-12 col-md-6 text-center mt-5">
                        <img className="iconFooterMarcas" src={process.env.PUBLIC_URL + "/images/logos/logo-tecnoliote.png"} width="50%" />
                    </div>
                    <div className="col-12 col-md-6 text-center mt-1">
                        <img className="iconFooterMarcas" src={process.env.PUBLIC_URL + "/images/logos/mirage_blanco.png"} width="50%" />
                    </div>
                </div>
                <div className="row text-center pt-2">
                    <div className="col-6 text-center">
                        <Link to="https://www.marthadebayle.com/" target="_blank"> <img className="iconFooter mt-3" src={process.env.PUBLIC_URL + "/images/logos/LOGO MD FOOTER.png"} /></Link>
                    </div>
                    <div className="col-6 text-center">
                        <Link to="https://wradio.com.mx" target="_blank"><img className="iconFooterW" src={process.env.PUBLIC_URL + "/images/logos/LOGO W RADIO FOOTER.png"} /></Link>
                    </div>
                    <div className="col-12 mt-4">
                        <h5 className="titulosCasate mb-4">#ENCHULAMELACASA</h5>
                        <p className="titulosMons">ALEJANDRO DUMAS 241 / COL. POLANCO REFORMA / CP 11550 / MÉXICO CDMX / TELÉFONO 9126 2222</p>
                    </div>
                    <div className="col-sm-12 text-center mt-2 mb-3">
                        <p className="letrasFooter">© Todos los Derechos Reservados de Media Marketing Knowledge Group www.mmkgroup.com.mx Prohibida la reproducción total o parcial, incluyendo cualquier medio electrónico o magnético. El valor total del premio es de 420 mil pesos en efectivo y libre de impuestos al primer lugar.</p>
                    </div>
                    <div className="col-12 mb-4">
                        <Link className="letrasFooter" to="https://www.marthadebayle.com/v3/sin-categoria/politica-de-privacidad/">Politica de Privacidad</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;