import { Element } from "react-scroll";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

const Anteriores = () => {
    const [posts, setPosts] = useState([]);
    const getPosts = useCallback(async () => {
        var millis = Date.now();
        try {
            return await axios.get('https://www.marthadebayle.com/wp-json/wp/v2/posts?per_page=4&_embed&tags=52240&t=' + millis);
        } catch (e) {
            console.error(e.message);
        }
    });

    useEffect(() => {
        getPosts()
            .then((response) => {
                console.info(response);
                if (response.status === 200) {
                    setPosts(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    return (
        <>
            <Element id="anteriores" className="section pt-5 pb-5">
                <h1 className="titulos">Años Anteriores</h1>
                <div className="container anterioresContainer">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Anterior</button>
                            <button className="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">2024</button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">
                            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <iframe width="100%" height="502" src="https://www.youtube.com/embed/absk6Zod2YM" title="Enchúlame la Casa con Martha Debayle, la revelación." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                                    </div>
                                    <div class="carousel-item">
                                        <iframe width="100%" height="502" src="https://www.youtube.com/embed/2grZ57pG2FE" title="Enchúlame la Casa por Martha Debayle - Episodio 4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                                    </div>
                                    <div class="carousel-item">
                                        <iframe width="100%" height="502" src="https://www.youtube.com/embed/45nONiWybqc" title="Enchúlame la Casa por Martha Debayle - Episodio 3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                                    </div>
                                    <div class="carousel-item">
                                        <iframe width="100%" height="502" src="https://www.youtube.com/embed/2cRsEt96o2c" title="Enchúlame la Casa por Martha Debayle Episodio 2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex="0">
                            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <iframe width="100%" height="502" src="https://www.youtube.com/embed/M3bil9Raeao" title="Enchúlame la Casa por Martha Debayle 2017" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                    <div class="carousel-item">
                                        <iframe width="100%" height="502" src="https://www.youtube.com/embed/a4GavcUymHE" title="Enchúlame la Casa 2018 por Martha Debayle" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            {posts.map((post, index) => {
                                                return (
                                                    <div className="col-12 col-sm-4">
                                                        <div className="card">
                                                            <a href={post.link} target={"_blank"}>
                                                                <img src={post._embedded['wp:featuredmedia']['0'].source_url} alt="" className={"card-img-top"} />
                                                            </a>
                                                            <div className="card-body">
                                                                <h5 className="card-title">{post.title.rendered}</h5>
                                                                <p className="card-text"
                                                                    dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Element>
        </>
    )
}
export default Anteriores;